export const COLORS = {
  BACKGROUND: '#f9f9f9',
  GREYISH_BROWN: '#4d4d4d',
  BROWNISH_GREY: '#717171',
  GREYISH: '#a3a3a2',
  BLACK: '#282828',
  BLACK_TWO: '#131313',
  BLACK_TWO_01: 'rgba(19, 19, 19, 0.1)',
  DULL_PINK: '#d482a2',
  MAUVE: '#ab6689',
  ERROR: '#a02020',
}

export const DURATION = {
  SHORTEST: 150,
  SHORTER: 200,
  SHORT: 250,
  STANDARD: 300,
  MEDIUM: 500,
  LONG: 800,
  LONGER: 1000,
  LONGEST: 1200,
}

export const EASING = {
  EASE_IN_OUT: 'cubic-bezier(0.4, 0, 0.2, 1)',
  EASE_OUT: 'cubic-bezier(0, 0, 0.2, 1)',
}

export const UNIT = 8

export default {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1100,
      xl: 1240,
    },
  },
  typography: {
    fontFamily: '"Merge", "Arial", sans-serif',
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: COLORS.GREYISH_BROWN,
      main: COLORS.BLACK,
      dark: COLORS.BLACK_TWO,
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(255, 255, 255, 0.3)',
      main: 'rgba(255, 255, 255, 0.2)',
      dark: 'rgba(255, 255, 255, 0.1)',
      contrastText: '#fff',
    },
    background: {
      default: COLORS.BACKGROUND,
    },
    error: {
      light: 'rgba(255, 255, 255, 0.8)',
      main: 'rgba(255, 255, 255, 0.8)',
      dark: 'rgba(255, 255, 255, 0.8)',
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
        margin: 0,
      },
    },
    MuiButton: {
      root: {
        color: 'inherit',
        fontSize: 12,
        lineHeight: 2,
        letterSpacing: 1,
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
      label: {
        paddingTop: 3,
      },
      outlined: {
        borderColor: COLORS.BLACK,
      },
    },
    MuiIconButton: {
      root: {
        color: 'inherit',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 28,
      },
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(255, 255, 255, 0.5)',
        '&$focused': {
          color: 'rgba(255, 255, 255, 0.8) !important',
        },
      },
    },
    MuiInput: {
      input: {
        color: 'white',
      },
      underline: {
        '&::before, &:hover': {
          borderBottomColor: 'rgba(255, 255, 255, 0.4) !important',
        },
        '&::after': {
          borderBottomColor: 'white',
        },
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 18,
        marginBottom: 0,
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: 'rgba(40, 40, 40, 0.9)',
      },
    },
    MuiMenuItem: {
      root: {
        color: 'white',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        color: 'white',
        top: 'calc(50% - 10px)',
        fontSize: 20,
      },
    },
    MuiListItem: {
      root: {
        '&:hover, &:focus': {
          backgroundColor: `${COLORS.BLACK} !important`,
        },
        '&$selected': {
          backgroundColor: `${COLORS.BLACK} !important`,
          '&:hover, &:focus': {
            backgroundColor: `${COLORS.BLACK} !important`,
          },
        },
      },
    },
  },
}
