import React from 'react'
import PropTypes from 'prop-types'
import ScrollableAnchor from 'react-scrollable-anchor'
import withWidth from '@material-ui/core/withWidth'
import Grid from '@material-ui/core/Grid'

import ScrollAnimation from '../_common/ScrollAnimation'
import './style.scss'

const features = [
  {id: 1, title: 'Download App', text: 'Download the app to start rescuing food today.'},
  {id: 2, title: 'Discover food', text: 'Find food that\'s ready to rescue near you.'},
  {
    id: 3,
    title: 'Order & Pay',
    text: 'Order and pay within the app and your food is ready to collect.',
  },
  {
    id: 4,
    title: 'Collect & Enjoy',
    text: 'Show your order and enjoy a tasty meal while reducing your foodprint.',
  },
]

About.propTypes = {
  width: PropTypes.string.isRequired,
}

function About({width}) {
  return (
    <ScrollableAnchor id="how-it-works">
      <section className="About Section">
        <div className="Container">
          <h2 className="Title">How it works?</h2>
          <Grid container component="ul" spacing={40}>
            {features.map((feature, index) => (
              <Grid item key={feature.id} component="li" className="About-Item" xs={12} sm={3}>
                <ScrollAnimation
                  className={`About-Feature About-Feature_${feature.id}`}
                  effect="fadeInTopSmall"
                  delay={width !== 'xs' ? index * 100 : 0}
                >
                  <h3 className="About-FeatureTitle">{feature.title}</h3>
                  <p className="About-FeatureText">{feature.text}</p>
                </ScrollAnimation>
              </Grid>
            ))}
          </Grid>
        </div>
      </section>
    </ScrollableAnchor>
  )
}

export default withWidth()(About)
