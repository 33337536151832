import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import Waypoint from 'react-waypoint'
import cn from 'classnames'
import {configureAnchors} from 'react-scrollable-anchor'
import withWidth from '@material-ui/core/withWidth'
import withStyles from '@material-ui/core/styles/withStyles'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/MenuRounded'

import ScrollAnimation from '../_common/ScrollAnimation'
import Navigation from '../_common/Navigation'
import Menu from './Menu'
import logoImg from '../../assets/img/logo.svg'
import {DURATION} from '../../styles/theme'
import './style.scss'

const transitionDuration = {enter: DURATION.MEDIUM, exit: DURATION.STANDARD}

const handleButtonClick = () => setTimeout(() => document.getElementById('name').focus(), 0)

const handleConfigureAnchors = width => {
  configureAnchors({
    offset: width === 'xs' ? -84 : width === 'sm' ? -94 : -120,
    scrollDuration: 1000,
    keepLastAnchorHash: false,
  })
}

const StyledIconButton = withStyles({
  root: {
    right: -15,
  },
})(IconButton)

const StyledButton = withStyles({
  root: {
    fontSize: 10,
  },
})(Button)

class Header extends Component {
  state = {
    width: PropTypes.string.isRequired,
    isSticky: true,
    isDrawerOpen: false,
  }

  componentDidMount() {
    const {width} = this.props
    handleConfigureAnchors(width)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.width !== nextProps.width) {
      handleConfigureAnchors(nextProps.width)
    }
  }

  handleToggleDrawer = () =>
    this.setState(state => ({
      isDrawerOpen: !state.isDrawerOpen,
    }))

  handleWaypointLeave = () => {
    const {isSticky} = this.state
    !isSticky && this.setState({isSticky: true})
  }

  handleWaypointEnter = () => {
    const {isSticky} = this.state
    isSticky && this.setState({isSticky: false})
  }

  render() {
    const {isSticky, isDrawerOpen} = this.state
    return (
      <Fragment>
        <Waypoint onLeave={this.handleWaypointLeave} onEnter={this.handleWaypointEnter} />
        <header className={cn('Header', {Header_sticky: isSticky})}>
          <div className="Header-Content Container">
            <div className="Header-LogoBlock">
              <div className="Header-LogoImgBlock">
                <img className="Header-LogoImg" src={logoImg} alt="Foodprint" />
              </div>
              <h1 className="Header-Title">Foodprint</h1>
            </div>
            <Hidden mdDown>
              <Navigation />
              <ScrollAnimation effect="fadeInTop" delay={DURATION.LONGER}>
                <StyledButton component="a" variant="outlined" href="#contact-us" onClick={handleButtonClick}>
                  Become a partner
                </StyledButton>
              </ScrollAnimation>
            </Hidden>
            <Hidden lgUp>
              <StyledIconButton onClick={this.handleToggleDrawer}>
                <MenuIcon />
              </StyledIconButton>
              <Drawer anchor="top" open={isDrawerOpen} transitionDuration={transitionDuration}>
                <Menu onToggleDrawer={this.handleToggleDrawer} />
              </Drawer>
            </Hidden>
          </div>
        </header>
      </Fragment>
    )
  }
}

export default withWidth()(Header)
