import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import Hidden from '@material-ui/core/Hidden'

import FeaturesList from './FeaturesList'
import FeaturesSwiper from './FeaturesSwiper'
import {ReactComponent as ListIcon} from '../../assets/icons/list.svg'
import {ReactComponent as ListSvg} from '../../assets/img/features/list.svg'
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg'
import {ReactComponent as SearchSvg} from '../../assets/img/features/search.svg'
import {ReactComponent as FavouritesIcon} from '../../assets/icons/favourites.svg'
import {ReactComponent as FavouritesSvg} from '../../assets/img/features/favourites.svg'
import {ReactComponent as OrdersIcon} from '../../assets/icons/orders.svg'
import {ReactComponent as OrdersSvg} from '../../assets/img/features/orders.svg'
import {ReactComponent as MapIcon} from '../../assets/icons/map.svg'
import {ReactComponent as MapSvg} from '../../assets/img/features/map.svg'
import {ReactComponent as FiltersIcon} from '../../assets/icons/filters.svg'
import {ReactComponent as FiltersSvg} from '../../assets/img/features/filters.svg'
import {ReactComponent as StarIcon} from '../../assets/icons/star.svg'
import {ReactComponent as ReviewSvg} from '../../assets/img/features/review.svg'
import {ReactComponent as SaleItemsIcon} from '../../assets/icons/sale-items.svg'
import {ReactComponent as SaleItemsSvg} from '../../assets/img/features/sale-items.svg'

const features = [
  {
    id: 1,
    Icon: ListIcon,
    Svg: ListSvg,
    title: 'List',
    text: 'Use the list to scroll through nearby eateries that have food ready to rescue',
  },
  {
    id: 2,
    Icon: SearchIcon,
    Svg: SearchSvg,
    title: 'Search',
    text: "Feeling like sushi? Simply search by food item to find what you're craving",
  },
  {
    id: 3,
    Icon: FavouritesIcon,
    Svg: FavouritesSvg,
    title: 'Favourites',
    text: 'Receive notifications when your favourite eateries have food for you to rescue',
  },
  {id: 4, Icon: OrdersIcon, Svg: OrdersSvg, title: 'Order', text: 'Purchase your food items directly in the app'},
  {
    id: 5,
    Icon: MapIcon,
    Svg: MapSvg,
    title: 'Map',
    text: "View the map to see the eateries and food available near you",
  },
  {
    id: 6,
    Icon: FiltersIcon,
    Svg: FiltersSvg,
    title: 'Filters',
    text: 'Use the filters to select the price, distance, dietary requirements, and ratings that suit you',
  },
  {id: 7, Icon: StarIcon, Svg: ReviewSvg, title: 'Review', text: 'Leave a review to share your experience with others'},
  {
    id: 8,
    Icon: SaleItemsIcon,
    Svg: SaleItemsSvg,
    title: 'Sale items',
    text: "See details of each food item so you know exactly what you're getting",
  },
]

export default function Features() {
  return (
    <ScrollableAnchor id="features">
      <section className="Section">
        <div className="Container">
          <h2 className="Title">Features</h2>
          <Hidden smDown>
            <FeaturesList features={features} />
          </Hidden>
          <Hidden mdUp>
            <FeaturesSwiper features={features} />
          </Hidden>
        </div>
      </section>
    </ScrollableAnchor>
  )
}
