import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded'
import CheckIcon from '@material-ui/icons/CheckRounded'
import KeyboardArrowDowIcon from '@material-ui/icons/KeyboardArrowDownRounded'

const StyledErrorIcon = withStyles({
  root: {
    fontSize: 18,
    color: 'white',
  },
})(ErrorIcon)

const StyledCheckIconIcon = withStyles({
  root: {
    fontSize: 18,
    color: 'white',
  },
})(CheckIcon)

const inputErrorProps = {
  endAdornment: (
    <InputAdornment position="end">
      <StyledErrorIcon />
    </InputAdornment>
  ),
}

const inputValidateProps = {
  endAdornment: (
    <InputAdornment position="end">
      <StyledCheckIconIcon />
    </InputAdornment>
  ),
}

const selectProps = {
  IconComponent: KeyboardArrowDowIcon,
}

InputField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array,
  isCheck: PropTypes.bool,
}

export default function InputField({input, meta, options, isCheck, ...props}) {
  const isError = meta.error && meta.touched
  const InputProps = isError ? inputErrorProps : isCheck && meta.touched ? inputValidateProps : null
  return (
    <Fragment>
      <TextField
        {...input}
        fullWidth
        margin="dense"
        select={Boolean(options)}
        SelectProps={options ? selectProps : null}
        InputProps={InputProps}
        error={isError}
        {...props}
      >
        {options &&
          options.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
      </TextField>
      {isError && meta.error !== 'required' && <FormHelperText error>{meta.error}</FormHelperText>}
    </Fragment>
  )
}
