import React from 'react'
import Swiper from 'react-id-swiper'
import Button from '@material-ui/core/Button'

import agtechImg from '../../assets/img/awards/agtech.png'
import agtechImg2x from '../../assets/img/awards/agtech@2x.png'
import bestImg from '../../assets/img/awards/best.png'
import bestImg2x from '../../assets/img/awards/best@2x.png'
import gooddesignImg from '../../assets/img/awards/gooddesign.png'
import gooddesignImg2x from '../../assets/img/awards/gooddesign@2x.png'
import sustainableBusinessImg from '../../assets/img/awards/sustainable-business.png'
import sustainableBusinessImg2x from '../../assets/img/awards/sustainable-business@2x.png'
import ScrollAnimation from '../_common/ScrollAnimation'
import {UNIT, DURATION} from '../../styles/theme'
import './style.scss'

const rewards = [
  {
    id: 1,
    color: 'grey',
    type: 'Winner',
    img: [agtechImg, agtechImg2x],
    title: (
      <>
        AgTech
        <br />
        Breakthrough
        <br />
        Awards
      </>
    ),
    text: 'Direct to Consumer Service of the Year (USA)',
    url: 'https://agtechbreakthrough.com',
  },
  {
    id: 2,
    color: 'purple',
    type: 'Winner',
    img: [bestImg, bestImg2x],
    title: (
      <>
        Best Design
        <br />
        awards
      </>
    ),
    text: (
      <>
        Gold<span className="Awards-Description"> - Digital Products / Transactional, Services & Utility</span> Silver
        <span className="Awards-Description"> - Public Good Award</span> Finalist
        <span className="Awards-Description"> - Sustainable Product Design</span>
      </>
    ),
    url: 'https://bestawards.co.nz/public-good-award/public-good-award/foodprint/food-rescue-app/',
  },
  {
    id: 3,
    color: 'grass',
    type: 'Gold',
    img: [gooddesignImg, gooddesignImg2x],
    title: (
      <>
        Good Design
        <br />
        Awards
      </>
    ),
    text: 'Digital Design Apps and Software (Australia)',
    url: 'https://good-design.org/projects/foodprint',
  },
  {
    id: 4,
    color: 'green',
    type: 'Finalist',
    img: [sustainableBusinessImg, sustainableBusinessImg2x],
    title: (
      <>
        Sustainable
        <br />
        Business Awards
      </>
    ),
    text: (
      <>
      Good Food
      <br />
      Going Circular
      </>),
    url: 'https://sustainable.org.nz/finalists-of-2020-sustainable-business-awards',
  },
]

const swiperSettings = {
  freeMode: true,
  slideToClickedSlide: true,
  slidesPerView: 4,
  spaceBetween: UNIT * 5,
  speed: DURATION.LONG,
  WrapperEl: 'ul',
  breakpoints: {
    440: {
      slidesPerView: 1.25,
    },
    600: {
      slidesPerView: 1.5,
    },
    960: {
      spaceBetween: UNIT * 3,
      slidesPerView: 2.25,
    },
    1100: {
      slidesPerView: 2.5,
    },
    1280: {
      slidesPerView: 3.5,
    },
  },
}

export default function Awards() {
  return (
    <section className="Awards">
      <ScrollAnimation effect="fadeInBottomSmall">
        <Swiper {...swiperSettings}>
          {rewards.map(({id, color, type, img, title, text, url}) => (
            <li key={id}>
              <div className={`Awards-Item Awards-Item_color_${color}`}>
                <div>
                  <p className="Awards-Type">{type}</p>
                  <img
                    className="Awards-Image"
                    width={280}
                    height={100}
                    src={img[0]}
                    srcSet={`${img[1]} 2x`}
                    alt={title}
                  />
                  <h3 className="Awards-Title">{title}</h3>
                </div>
                <p className="Awards-Text">{text}</p>
                <Button
                  color="secondary"
                  variant="contained"
                  component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={url}
                >
                  Find out more
                </Button>
              </div>
            </li>
          ))}
        </Swiper>
      </ScrollAnimation>
    </section>
  )
}
