import React from 'react'
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

// import Modal from '../Modal'
import Header from '../Header'
import Banner from '../Banner'
import About from '../About'
import Features from '../Features'
import Mission from '../Mission'
import Awards from '../Awards'
import SupportedBy from '../SupportedBy'
import Eateries from '../Eateries'
import Particularities from '../Particularities'
import Contacts from '../Contacts'
import Footer from '../Footer'
import theme from '../../styles/theme'

const muiTheme = createMuiTheme(theme)

export default function App() {
  return (
    <MuiThemeProvider theme={muiTheme}>
      {/* <Modal /> */}
      <div className="FadeIn">
        <CssBaseline />
        <Header />
        <main>
          <Banner />
          <About />
          <Features />
          <Mission />
          <Awards />
          <SupportedBy />
          <div className="Pattern">
            <Eateries />
            <Particularities />
            <Contacts />
            <Footer />
          </div>
        </main>
      </div>
    </MuiThemeProvider>
  )
}
