import React, {Component} from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/CloseRounded'

import Social from '../../_common/Social'
import Navigation from '../../_common/Navigation'
import logoImg from '../../../assets/img/logo.svg'
import './style.scss'

const StyledIconButton = withStyles({
  root: {
    position: 'absolute',
    top: 30,
    right: 15,
  },
})(IconButton)

export default class Menu extends Component {
  static propTypes = {
    onToggleDrawer: PropTypes.func.isRequired,
  }

  handleButtonClick = () => {
    this.props.onToggleDrawer()
    setTimeout(() => document.getElementById('name').focus(), 0)
  }

  render() {
    const {onToggleDrawer} = this.props
    return (
      <aside className="Menu FadeIn">
        <StyledIconButton onClick={onToggleDrawer}>
          <CloseIcon />
        </StyledIconButton>
        <div className="Menu-Content">
          <div className="Menu-MainContent">
            <div className="Menu-Logo">
              <img width={35} src={logoImg} alt="Foodprint" />
              <h2 className="Menu-Title">Foodprint</h2>
            </div>
            <Navigation onToggleDrawer={onToggleDrawer} />
            <Button component="a" variant="outlined" href="#contact-us" onClick={this.handleButtonClick}>
              Become a partner
            </Button>
          </div>
          <Social isMenu />
        </div>
      </aside>
    )
  }
}
