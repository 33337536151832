import React from 'react'
import PropTypes from 'prop-types'
import withWidth from '@material-ui/core/withWidth'

import './style.scss'
import chqImg from '../../assets/img/support/chq.png'
import chqImg2x from '../../assets/img/support/chq@2x.png'
import sproutImg from '../../assets/img/support/sprout.png'
import sproutImg2x from '../../assets/img/support/sprout@2x.png'
import aucklandCouncilImg from '../../assets/img/support/auckland-council.png'
import aucklandCouncilImg2x from '../../assets/img/support/auckland-council@2x.png'
import wellingtonCouncilImg from '../../assets/img/support/wellington-council.png'
import wellingtonCouncilImg2x from '../../assets/img/support/wellington-council@2x.png'
import christchurchCouncilImg from '../../assets/img/support/christchurch-council.png'
import christchurchCouncilImg2x from '../../assets/img/support/christchurch-council@2x.png'
import nelsonCouncilImg from '../../assets/img/support/nelson-council.png'
import nelsonCouncilImg2x from '../../assets/img/support/nelson-council@2x.png'
import ScrollAnimation from '../_common/ScrollAnimation'

SupportedBy.propTypes = {
  width: PropTypes.string.isRequired,
}

const supporters = [
  {
    title: 'Creative HQ',
    link: 'creativehq.co.nz',
    images: [chqImg, chqImg2x],
  },
  {
    title: 'Sprout',
    link: 'sproutagritech.com',
    images: [sproutImg, sproutImg2x],
  },
  {
    title: 'Auckland Council',
    link: 'aucklandcouncil.govt.nz',
    images: [aucklandCouncilImg, aucklandCouncilImg2x],
  },
  {
    title: 'Wellington City Council',
    link: 'wellington.govt.nz',
    images: [wellingtonCouncilImg, wellingtonCouncilImg2x],
  },
  {
    title: 'Christchurch City Council',
    link: 'ccc.govt.nz',
    images: [christchurchCouncilImg, christchurchCouncilImg2x],
  },
  {
    title: 'Nelson City Council',
    link: 'nelson.govt.nz',
    images: [nelsonCouncilImg, nelsonCouncilImg2x],
  },
]

function SupportedBy({width}) {
  return (
    <section className="SupportedBy Section">
      <div className="Container">
        <h2 className="Title">Proudly supported by</h2>
        <ul className="SupportedBy-List">
          {supporters.map(({title, link, images}, index) => (
            <ScrollAnimation
              key={index}
              tag="li"
              effect="fadeInBottomSmall"
              delay={
                width === 'xs'
                  ? undefined
                  : width === 'sm'
                  ? (index % 3) * 100
                  : width === 'md'
                  ? (index % 4) * 100
                  : 100 * index
              }
            >
              <img
                className="SupportedBy-Image"
                width={160}
                height={100}
                src={images[0]}
                srcSet={`${images[1]} 2x`}
                alt={title}
              />
              <h3 className="SupportedBy-Title">{title}</h3>
              <a className="Link SupportedBy-Link " target="_blank" rel="noopener noreferrer" href={`https://${link}`}>
                {link}
              </a>
            </ScrollAnimation>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default withWidth()(SupportedBy)
