import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import ScrollAnimation from '../ScrollAnimation'
import './style.scss'

/*const links = ['how it works', 'features', 'our mission', 'for eateries', 'testimonials', 'contact us']*/
const links = ['how it works', 'features', 'our mission', 'for eateries', 'contact us']

Navigation.propTypes = {
  onToggleDrawer: PropTypes.func,
}

export default function Navigation({onToggleDrawer}) {
  const isMenu = Boolean(onToggleDrawer)
  return (
    <nav className={cn('Navigation', {Navigation_menu: isMenu})}>
      {links.map((link, index) => {
        const effect = index % 2 === 0 ? (isMenu ? 'fadeInLeft' : 'fadeInTop') : isMenu ? 'fadeInRight' : 'fadeInBottom'
        return (
          <ScrollAnimation key={link} tag="li" className="Navigation-Item" effect={effect} delay={100 * index}>
            <a className="Navigation-Link Link" href={`#${link.replace(/ /gi, '-')}`} onClick={onToggleDrawer || null}>
              {link}
            </a>
          </ScrollAnimation>
        )
      })}
    </nav>
  )
}
