import React from 'react'

import './style.scss'

export default function Footer() {
  return (
    <footer className="Footer">
      <div className="Footer-Content Container">
        <p className="Footer-Text">©2019 Foodprint. All rights reserved</p>
        <p className="Footer-Text">
          <button id="shield" className="Footer-Shield">
            <span className="VisuallyHidden">Shield link</span>
          </button>
        </p>
        <p className="Footer-Text">
          Created by{' '}
          <a className="Link Link_underline" target="_blank" rel="noopener noreferrer" href="https://7glyphs.com">
            7 glyphs Ltd.
          </a>
        </p>
      </div>
    </footer>
  )
}
