import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'

import {ReactComponent as EarsSvg} from '../../assets/img/contacts/ears.svg'
import ScrollAnimation from '../_common/ScrollAnimation'
import StoreButtons from '../_common/StoreButtons'
import Social from '../_common/Social'
import ContactForm from './ContactForm'
import {DURATION} from '../../styles/theme'
import './style.scss'

export default function Contacts() {
  return (
    <ScrollableAnchor id="contact-us">
      <section className="Contacts Section">
        <div className="Contacts-Wrapper">
          <EarsSvg className="Contacts-Ears" />
          <div className="Contacts-Content">
            <div className="Contacts-Block">
              <div>
                <ScrollAnimation tag="p" className="Contacts-Text" effect="fadeInLeft">
                  <b>Follow us and<br />download app</b>
                </ScrollAnimation>
                <ScrollAnimation effect="fadeInBottom" delay={DURATION.SHORTEST}>
                  <StoreButtons isContacts />
                </ScrollAnimation>
              </div>
              <Social />
            </div>
            <div className="Contacts-Block">
              <h2 className="Contacts-Title">Contact us</h2>
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </ScrollableAnchor>
  )
}
