import React, {Component} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Swiper from 'react-id-swiper'

import ScrollAnimation from '../../_common/ScrollAnimation'
import {UNIT, DURATION} from '../../../styles/theme'
import './style.scss'

const renderBullet = (index, className) =>
  '<span class="' + className + ' Focus' + (index % 2 !== 0 ? ' Focus_second"' : '"') + '></span>'

const settings = {
  slideToClickedSlide: true,
  preventClicksPropagation: false,
  centeredSlides: true,
  loop: true,
  slidesPerView: 'auto',
  spaceBetween: UNIT * 7,
  loopedSlides: 8,
  speed: DURATION.MEDIUM,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  pagination: {
    renderBullet,
    el: '.Bullets',
    clickable: true,
  },
}

export default class FeaturesSwiper extends Component {
  state = {
    swiper: null,
  }

  static propTypes = {
    features: PropTypes.array.isRequired,
  }

  handleSlideChange = () => this.setState({})

  swiperRef = ref => this.setState({swiper: ref && ref.swiper})

  render() {
    const {features} = this.props
    const {swiper} = this.state
    const swiperSettings = {
      ...settings,
      on: {
        slideChange: this.handleSlideChange,
      },
    }
    const activeSlide = swiper && swiper.realIndex + 1
    return (
      <div className="FeaturesSwiper">
        <Swiper {...swiperSettings} ref={this.swiperRef}>
          {features.map(feature => (
            <div key={feature.id} className="FeaturesSwiper-Slide">
              <h3 className="FeaturesSwiper-SlideTitle">
                <feature.Icon className="Icon Icon_noShrink" />
                <span className="FeaturesSwiper-SlideTitleText">{feature.title}</span>
              </h3>
              <p className="FeaturesSwiper-SlideText">{feature.text}</p>
            </div>
          ))}
        </Swiper>
        <div className="FeaturesSwiper-Block">
          <ScrollAnimation
            className={cn('FeaturesSwiper-Phone', {[`FeaturesSwiper-Phone_active_${activeSlide}`]: activeSlide})}
            effect="fadeInCenterBottom"
          />
        </div>
      </div>
    )
  }
}
