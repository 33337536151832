import React, {Fragment} from 'react'
import {isMobile} from 'react-device-detect'
import Plx from 'react-plx'
import Hidden from '@material-ui/core/Hidden'

import ScrollAnimation from '../_common/ScrollAnimation'
import StoreButtons from '../_common/StoreButtons'
import {DURATION} from '../../styles/theme'
import './style.scss'

const firstPhonePlxData = [
  {
    start: 0,
    end: 1000,
    properties: [
      {
        startValue: 0,
        endValue: 40,
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: -10,
        property: 'translateX',
      },
    ],
  },
]

const secondPhonePlxData = [
  {
    start: 0,
    end: 1000,
    properties: [
      {
        startValue: 0,
        endValue: 125,
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 5,
        property: 'rotate',
      },
    ],
  },
]

const renderStoreButtons = isMobile => (
  <ScrollAnimation effect="fadeInBottom" delay={isMobile ? 0 : DURATION.MEDIUM}>
    <StoreButtons />
  </ScrollAnimation>
)

export default function Banner() {
  return (
    <section className="Banner">
      <h2 className="VisuallyHidden">Banner</h2>
      <div className="Banner-Content Container">
        <ScrollAnimation tag="p" className="Banner-Title" effect="fadeInLeft" delay={DURATION.SHORTEST}>
          <b>Better for our world, one meal at a time</b>
        </ScrollAnimation>
        <ScrollAnimation tag="p" className="Banner-Text" effect="fadeInRight" delay={DURATION.SHORT}>
          Foodprint helps you reduce food waste and enjoy delicious meals at a fraction of the price.
        </ScrollAnimation>
        <div className="Banner-Blot">
          {isMobile ? (
            <Fragment>
              <div className="Banner-FirstPhone" />
              <div className="Banner-SecondPhone" />
            </Fragment>
          ) : (
            <Fragment>
              <Plx className="Banner-FirstPhone" parallaxData={firstPhonePlxData} />
              <Plx className="Banner-SecondPhone" parallaxData={secondPhonePlxData} />
            </Fragment>
          )}
        </div>
        <Hidden xsDown>{renderStoreButtons()}</Hidden>
        <Hidden smUp>{renderStoreButtons(true)}</Hidden>
      </div>
    </section>
  )
}
