import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import ScrollAnimation from '../_common/ScrollAnimation'
import './style.scss'

const handleButtonClick = () => setTimeout(() => document.getElementById('name').focus(), 0)

export default function Eateries() {
  return (
    <ScrollableAnchor id="for-eateries">
      <section className="Eateries Section">
        <div className="Container">
          <h2 className="Title Title_left">For eateries</h2>
          <Grid container spacing={40}>
            <Grid item xs={12} md={5}>
              <ScrollAnimation tag="p" className="Eateries-TitleText" effect="fadeIn">
                <b>Work in food? Partner with Foodprint today!</b>
              </ScrollAnimation>
              <ScrollAnimation tag="p" className="Eateries-Text" effect="fadeInBottomSmall">
                When you partner with Foodprint, you’re committing to reducing food waste and making positive change.
                You’re also exposing your business to our growing database of customers, building brand awareness and
                loyalty, and of course making a profit on food that used to be wasted!
              </ScrollAnimation>
              <ScrollAnimation tag="p" className="Eateries-Text" effect="fadeInBottomSmall">
                Whether you own a cafe, restaurant, supermarket, bakery, catering service, hotel, food truck or
                something we haven’t thought of, let’s chat and see how Foodprint can work for you. Simply click ‘Become
                A Partner’ below to get started and one of our team will be in touch to do the rest. Partnering with
                Foodprint is free and easy. We’ll help you get set up to ensure the best result for your eatery - and
                our world.
              </ScrollAnimation>
              <ScrollAnimation tag="p" className="Eateries-Text" effect="fadeInBottomSmall">
              Help us know where to grow. Foodprint currently operates in the Auckland, Waikato, Bay of Plenty, Wellington, Nelson, Canterbury and Otago regions. We have plans to expand to other areas soon. If you're out of our operating areas, please send us your details and we'll be in touch when we're in your region.
              </ScrollAnimation>
              <ScrollAnimation effect="fadeInBottom">
                <Button
                  component="a"
                  color="secondary"
                  variant="contained"
                  href="#contact-us"
                  onClick={handleButtonClick}
                >
                  Become a partner
                </Button>
              </ScrollAnimation>
            </Grid>
            <Grid item xs={12} md={7}>
              <ScrollAnimation className="Eateries-Img" effect="fadeInRight" />
            </Grid>
          </Grid>
        </div>
      </section>
    </ScrollableAnchor>
  )
}
