import React, {Component} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import ScrollAnimation from '../../_common/ScrollAnimation'
import './style.scss'

export default class FeaturesList extends Component {
  state = {
    activeFeature: null,
  }

  static propTypes = {
    features: PropTypes.array.isRequired,
  }

  handleActiveFeatureSet = activeFeature => () => this.setState({activeFeature})

  render() {
    const {features} = this.props
    const {activeFeature} = this.state
    return (
      <div className="FeaturesList">
        {features.map(preload => (
          <div key={preload.id} className={`FeaturesList-Preload FeaturesList-Preload_${preload.id}`} />
        ))}
        <ScrollAnimation
          className={cn('FeaturesList-Phone', {[`FeaturesList-Phone_active_${activeFeature}`]: activeFeature})}
          effect="fadeInCenterBottom"
        />
        <ul className={cn('FeaturesList-List', {'FeaturesList-List_active': activeFeature})}>
          {features.map((feature, index) => (
            <ScrollAnimation
              tag="li"
              key={feature.id}
              className={cn('FeaturesList-Item', {'FeaturesList-Item_active': activeFeature === feature.id})}
              effect={index >= 4 ? 'fadeInRight' : 'fadeInLeft'}
            >
              <div className="FeaturesList-ItemBlock" onMouseEnter={this.handleActiveFeatureSet(feature.id)} onMouseLeave={this.handleActiveFeatureSet(null)}>
                <feature.Svg className="FeaturesList-ItemLine" />
                <h3 className="FeaturesList-ItemTitle">
                  <feature.Icon className="Icon Icon_noShrink" />
                  <span className="FeaturesList-ItemTitleText">{feature.title}</span>
                </h3>
                <p className="FeaturesList-ItemText">{feature.text}</p>
              </div>
            </ScrollAnimation>
          ))}
        </ul>
      </div>
    )
  }
}
