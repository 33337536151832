import React, {Fragment} from 'react'
import cn from 'classnames'
import ScrollableAnchor from 'react-scrollable-anchor'
import {isMobile} from 'react-device-detect'
import Plx from 'react-plx'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import ScrollAnimation from '../_common/ScrollAnimation'
import './style.scss'

const firstSaladPlxData = [
  {
    start: '.Mission',
    end: 3500,
    properties: [
      {
        startValue: 200,
        endValue: -200,
        property: 'translateY',
      },
    ],
  },
]

const secondSaladPlxData = [
  {
    start: '.Mission',
    end: 3500,
    properties: [
      {
        startValue: -50,
        endValue: 150,
        property: 'translateY',
      }
    ],
  },
]

const renderSalads = isMobile => (
  <Fragment>
    <div className="Mission-FirstSalad" />
    <div className={cn('Mission-SecondSalad', {'Mission-SecondSalad_mobile': isMobile})} />
  </Fragment>
)

export default function Mission() {
  return (
    <ScrollableAnchor id="our-mission">
      <section className="Mission Section">
        <Hidden smUp>{renderSalads()}</Hidden>
        <Hidden xsDown>
          {isMobile ? (
            renderSalads(isMobile)
          ) : (
            <Fragment>
              <Plx className="Mission-FirstSalad" parallaxData={firstSaladPlxData} />
              <Plx className="Mission-SecondSalad" parallaxData={secondSaladPlxData} />
            </Fragment>
          )}
        </Hidden>
        <div className="Mission-Content Container">
          <h2 className="Title">Our mission</h2>
          <ScrollAnimation tag="p" className="Mission-TitleText" effect="fadeIn">
            <b>Food waste, it’s a thing. It doesn’t have to be.</b>
          </ScrollAnimation>
          <Grid container spacing={40}>
            <Hidden xsDown>
              <Grid item sm={1} md={2} />
            </Hidden>
            <Grid item xs={12} sm={5} md={4}>
              <ScrollAnimation tag="p" className="Mission-Text" effect="fadeInLeft">
                Globally one-third of all food produced for human consumption is wasted. Food that ends up in landfill
                decomposes and emits the greenhouse gas methane, which is damaging for our environment. The problem is
                so big that if food waste was a country it would be the third largest greenhouse gas emitter, only
                behind China and the United States.
              </ScrollAnimation>
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <ScrollAnimation tag="p" className="Mission-Text Mission-Text_second" effect="fadeInRight">
                In New Zealand alone, cafes, restaurants and supermarkets produce 50 000 tonnes of food waste. Over 60%
                of this is completely avoidable.<br />This is why we started Foodprint.<br />We will redirect this food to you,
                our hungry friends, and better still; it'll only cost you a fraction of the regular price.
              </ScrollAnimation>
            </Grid>
            <Hidden xsDown>
              <Grid item sm={1} md={2} />
            </Hidden>
          </Grid>
        </div>
      </section>
    </ScrollableAnchor>
  )
}
